exports.components = {
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-account-address-index-js": () => import("./../../../src/pages/account/address/index.js" /* webpackChunkName: "component---src-pages-account-address-index-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-order-details-index-js": () => import("./../../../src/pages/account/order-details/index.js" /* webpackChunkName: "component---src-pages-account-order-details-index-js" */),
  "component---src-pages-account-orders-index-js": () => import("./../../../src/pages/account/orders/index.js" /* webpackChunkName: "component---src-pages-account-orders-index-js" */),
  "component---src-pages-allergy-index-js": () => import("./../../../src/pages/allergy/index.js" /* webpackChunkName: "component---src-pages-allergy-index-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-create-account-index-js": () => import("./../../../src/pages/create-account/index.js" /* webpackChunkName: "component---src-pages-create-account-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-[id]-js": () => import("./../../../src/pages/menu/[id].js" /* webpackChunkName: "component---src-pages-menu-[id]-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-our-philosophy-index-js": () => import("./../../../src/pages/our-philosophy/index.js" /* webpackChunkName: "component---src-pages-our-philosophy-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-sign-in-index-js": () => import("./../../../src/pages/sign-in/index.js" /* webpackChunkName: "component---src-pages-sign-in-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-pages-track-order-[id]-js": () => import("./../../../src/pages/track-order/[id].js" /* webpackChunkName: "component---src-pages-track-order-[id]-js" */),
  "component---src-pages-track-order-index-js": () => import("./../../../src/pages/track-order/index.js" /* webpackChunkName: "component---src-pages-track-order-index-js" */)
}

